export const opcionesRegimenFiscal = [
  { value: 'GENERAL_DE_LEY_PERSONAS_MORALES', label: 'GENERAL DE LEY PERSONAS MORALES' },
  { value: 'PERSONAS_MORALES_CON_FINES_NO_LUCRATIVOS', label: 'PERSONAS MORALES CON FINES NO LUCRATIVOS' },
  { value: 'SUELDOS_Y_SALARIOS_E_INGRESOS_ASIMILADOS_A_SALARIOS', label: 'SUELDOS Y SALARIOS E INGRESOS ASIMILADOS A SALARIOS' },
  { value: 'ARRENDAMIENTO', label: 'ARRENDAMIENTO' },
  { value: 'REGIMEN_DE_ENAJENACION_O_ADQUISICION_DE_BIENES', label: 'REGIMEN DE ENAJENACION O ADQUISICION DE BIENES' },
  { value: 'DEMAS_INGRESOS', label: 'DEMAS INGRESOS' },
  { value: 'RESIDENTES_EN_EL_EXTRANJERO_SIN_ESTABLECIMIENTO_PERMANENTE_EN_MEXICO', label: 'RESIDENTES EN EL EXTRANJERO SIN ESTABLECIMIENTO PERMANENTE EN MEXICO' },
  { value: 'INGRESOS_POR_DIVIDENDOS_(SOCIOS_Y_ACCIONISTAS)', label: 'INGRESOS POR DIVIDENDOS (SOCIOS Y ACCIONISTAS)' },
  { value: 'PERSONAS_FISICAS_CON_ACTIVIDADES_EMPRESARIALES_Y_PROFESIONALES', label: 'PERSONAS FISICAS CON ACTIVIDADES EMPRESARIALES Y PROFESIONALES' },
  { value: 'INGRESOS_POR_INTERESES', label: 'INGRESOS POR INTERESES' },
  { value: 'REGIMEN_DE_LOS_INGRESOS_POR_OBTENCION_DE_PREMIOS', label: 'REGIMEN DE LOS INGRESOS POR OBTENCION DE PREMIOS' },
  { value: 'SIN_OBLIGACIONES_FISCALES', label: 'SIN OBLIGACIONES FISCALES' },
  { value: 'SOCIEDADES_COOPERATIVAS_DE_PRODUCCION_QUE_OPTAN_POR_DIFERIR_SUS_INGRESOS', label: 'SOCIEDADES COOPERATIVAS DE PRODUCCION QUE OPTAN POR DIFERIR SUS INGRESOS' },
  { value: 'INCORPORACION_FISCAL', label: 'INCORPORACION FISCAL' },
  { value: 'ACTIVIDADES_AGRICOLAS_GANADERAS_SILVICOLAS_Y_PESQUERAS', label: 'ACTIVIDADES AGRICOLAS GANADERAS SILVICOLAS Y PESQUERAS' },
  { value: 'OPCIONAL_PARA_GRUPOS_DE_SOCIEDADES', label: 'OPCIONAL PARA GRUPOS DE SOCIEDADES' },
  { value: 'COORDINADOS', label: 'COORDINADOS' },
  { value: 'REGIMEN_DE_LAS_ACTIVIDADES_EMPRESARIALES_CON_INGRESOS_A_TRAVES_DE_PLATAFORMAS_TECNOLOGICAS', label: 'REGIMEN DE LAS ACTIVIDADES EMPRESARIALES CON INGRESOS A TRAVES DE PLATAFORMAS TECNOLOGICAS' },
  { value: 'REGIMEN_SIMPLIFICADO_DE_CONFIANZA', label: 'REGIMEN SIMPLIFICADO DE CONFIANZA' },
];
