export const opcionesUsoCfdi = [
    { value: "G01", label: "ADQUISICION DE MERCANCIAS" },
    { value: "G02", label: "DEVOLUCIONES, DESCUENTOS O BONIFICACIONES" },
    { value: "G03", label: "GASTOS EN GENERAL" },
    { value: "I01", label: "CONSTRUCCIONES" },
    { value: "I02", label: "MOBILIARIO Y EQUIPO DE OFICINA POR INVERSIONES" },
    { value: "I03", label: "EQUIPO DE TRANSPORTE" },
    { value: "I04", label: "EQUIPO DE COMPUTO Y ACCESORIOS" },
    { value: "I05", label: "DADOS, TROQUELES, MOLDES, MATRICES Y HERRAMENTAL" },
    { value: "I06", label: "COMUNICACIONES TELEFONICAS" },
    { value: "I07", label: "COMUNICACIONES SATELITALES" },
    { value: "I08", label: "OTRA MAQUINARIA Y EQUIPO" },
    { value: "D01", label: "HONORARIOS MEDICOS, DENTALES Y GASTOS HOSPITALARIOS" },
    { value: "D02", label: "GASTOS MEDICOS POR INCAPACIDAD O DISCAPACIDAD" },
    { value: "D03", label: "GASTOS FUNERALES" },
    { value: "D04", label: "DONATIVOS" },
    { value: "D05", label: "INTERESES REALES EFECTIVAMENTE PAGADOS POR CREDITOS HIPOTECARIOS (CASA HABITACION)" },
    { value: "D06", label: "APORTACIONES VOLUNTARIAS AL SAR" },
    { value: "D07", label: "PRIMAS POR SEGUROS DE GASTOS MÉDICOS" },
    { value: "D08", label: "GASTOS DE TRANSPORTACION ESCOLAR OBLIGATORIA" },
    { value: "D09", label: "DEPOSITOS EN CUENTAS PARA EL AHORRO, PRIMAS QUE TENGAN COMO BASE PLANES DE PENSIONES" },
    { value: "D10", label: "PAGOS POR SERVICIOS EDUCATIVOS (COLEGIATURAS)" },
    { value: "S01", label: "SIN EFECTOS FISCALES" },
    { value: "CP01", label: "PAGOS" },
    { value: "CN01", label: "NOMINA" },
  ];
