import React from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@mui/material";
import { opcionesRegimenFiscal } from "../utils/OpcionesRegimenFiscal";
import { opcionesUsoCfdi } from "../utils/OpcionesUsoCfdi";

const FormularioCampos = ({
  rfcCliente,
  setRfcCliente,
  razonSocial,
  setRazonSocial,
  regimen,
  setRegimen,
  usoCfdi,
  setUsoCfdi,
  codPostal,
  setCodPostal,
  email,
  setEmail,
  rfcError,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          label="RFC"
          variant="outlined"
          fullWidth
          value={rfcCliente || ""}
          onChange={(e) => setRfcCliente(e.target.value)}
          required
          margin="normal"
          error={Boolean(rfcError)}
          helperText={rfcError}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Razón Social"
          variant="outlined"
          fullWidth
          value={razonSocial || ""}
          onChange={(e) => setRazonSocial(e.target.value)}
          required
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="regimen-label" required>
            Régimen Fiscal
          </InputLabel>
          <Select
            labelId="regimen-label"
            id="regimen"
            value={regimen}
            onChange={(e) => setRegimen(e.target.value)}
            label="Regimen Fiscal"
            required
          >
            <MenuItem value="" disabled>
              Seleccione
            </MenuItem>
            {opcionesRegimenFiscal.map((opcion) => (
              <MenuItem key={opcion.value} value={opcion.value}>
                {opcion.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="usoCfdi-label" required>
            Uso del CFDI
          </InputLabel>
          <Select
            labelId="usoCfdi-label"
            id="usoCfdi"
            value={usoCfdi}
            onChange={(e) => setUsoCfdi(e.target.value)}
            label="Uso del CFDI"
            required
          >
            <MenuItem value="" disabled>
              Seleccione
            </MenuItem>
            {opcionesUsoCfdi.map((opcion) => (
              <MenuItem key={opcion.value} value={opcion.value}>
                {opcion.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Código postal"
          variant="outlined"
          fullWidth
          value={codPostal || ""}
          onChange={(e) => setCodPostal(e.target.value)}
          required
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Correo Electrónico"
          variant="outlined"
          fullWidth
          type="email"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          required
          margin="normal"
        />
      </Grid>
    </Grid>
  );
};

export default FormularioCampos;
