import React from "react";
import axios from "axios";
import { Paper, Typography, Box, Button } from "@mui/material";
import { CloudDownload } from "@mui/icons-material";

const SuccessFeedback = ({ datosComprobante, orderMercadoLibre }) => {
  const handleDownload = (type, data) => {
    if (type === "xml") {
      const xmlBlob = new Blob([data], { type: "text/xml" });

      const orderId = orderMercadoLibre[0].orderIdPackIdLabel;
      const fileName = `ventaMercadoLibre${orderId}.xml`;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(xmlBlob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }

    if (type === "pdf") {
      receiptUrlPdf();
    }

    console.log(`Descargando ${type}:`, data);
  };

  const receiptUrlPdf = () => {
    axios
      .get(`${window._env_.REACT_APP_BFF_URL}/receiptUrl`, {
        params: {
          idCompVenta: datosComprobante.idCompVenta,
          tokenComp: datosComprobante.tokenComp,
          formato: "CARTA",
        },
      })
      .then((response) => {
        window.open(response.data?.url);
      });
  };

  return (
    <Paper
      elevation={4}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "40px",
        padding: "40px",
        width: "70vh",
        borderRadius: "12px",
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography variant="h5" gutterBottom>
        ¡Factura generada exitosamente!
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: "24px", color: "#0763e7" }}
      >
        Tu factura está lista para su descarga.
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button
          onClick={() => handleDownload("xml", datosComprobante.xml)}
          variant="contained"
          style={{
            marginRight: "16px",
            fontSize: "18px",
            color: "#fff",
            backgroundColor: "#0763e7",
          }}
          startIcon={<CloudDownload />}
        >
          Descargar XML
        </Button>
        <Button
          onClick={() => handleDownload("pdf", datosComprobante.pdf)}
          variant="contained"
          style={{
            fontSize: "18px",
            color: "#fff",
            backgroundColor: "#0763e7",
          }}
          startIcon={<CloudDownload />}
        >
          Descargar PDF
        </Button>
      </Box>
    </Paper>
  );
};

export default SuccessFeedback;
