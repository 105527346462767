import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/App.css";
import "./Facturacion.css";
import { Typography } from "@mui/material";
import "@fontsource/poppins";
import { useParams } from "react-router-dom";
import DuxLogo from "../../assets/dux-logo.png";
import Formulario from "../Formulario/Formulario";
import Footer from "../Footer/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Facturacion = () => {
  const { token } = useParams();
  const [orderMercadoLibre, setOrderMercadoLibre] = useState("");
  const [
    orderMercadoLibreNotFoundMessage,
    setOrderMercadoLibreNotFoundMessage,
  ] = useState("");
  const [showFormulario, setShowFormulario] = useState(false);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    axios
      .get(`${window._env_.REACT_APP_BFF_URL}/orderMercadoLibre`, {
        params: {
          orderToken: token,
        },
      })
      .then((response) => {
        setOrderMercadoLibre(response.data);
        setOrderMercadoLibreNotFoundMessage(null);
        setShowComponent(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setOrderMercadoLibreNotFoundMessage(
            "No se encontró el pedido. Consulte con el software de gestión."
          );
        } else {
          setOrderMercadoLibreNotFoundMessage(
            "Ocurrió un error, contacta con el administrador del sistema."
          );
        }
        setShowComponent(true);
      });
  }, [token]);

  const handleFacturarClick = () => {
    axios
      .post(`${window._env_.REACT_APP_BFF_URL}/validateOrder`, {
        orderToken: token,
      })
      .then((response) => {
        setShowFormulario(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          const errorMessage = JSON.parse(error.response.data.message).message;
          toast.error(`${errorMessage}`, {
            position: "top-right",
            style: {
              borderRadius: "8px",
              border: "2px solid #b32424",
              fontFamily: "Poppins",
            },
          });
        } else {
          toast.error(
            "Ocurrió un error. Por favor, contacta al administrador del sistema.",
            { position: "top-right" }
          );
        }
      });
  };

  const handleCancelInvoiceForm = () => {
    setShowFormulario(false);
  };

  return (
    <div className={`App ${showComponent ? 'show' : ''}`}>
      <ToastContainer />
      {!showFormulario && orderMercadoLibre && (
        <div className="contenedorCaja">
          <img src={DuxLogo} alt="Dux Logo" className="duxLogo" />

          <div className="cajaConHeaderAzul">
            <div className="headerContent">
              <h2>Sistema de Facturación</h2>
            </div>

            <div className="inputContainer">
              <input
                type="text"
                value={orderMercadoLibre[0].orderIdPackIdLabel}
                className="orderIdInput"
                disabled
              />
            </div>

            <div className="facturarButtonContainer">
              {orderMercadoLibre && (
                <button
                  className="facturarButton"
                  onClick={handleFacturarClick}
                >
                  Facturar
                </button>
              )}
            </div>
          </div>

          <Footer />
        </div>
      )}

      {showFormulario && (
        <Formulario
          onCancel={handleCancelInvoiceForm}
          orderMercadoLibre={orderMercadoLibre}
          orderToken={token}
        />
      )}

      {orderMercadoLibreNotFoundMessage && (
        <div
          style={{ flexDirection: "column", textAlign: "center" }}
        >
          <img src={DuxLogo} className="duxLogo" alt="Dux Logo" />

          <Typography variant="h1" mt={5} fontFamily="Poppins" fontSize="25px">
            {orderMercadoLibreNotFoundMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Facturacion;
