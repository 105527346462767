import React from "react";
import { Typography, Paper } from "@mui/material";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";

const LoadingScreen = () => {
  const [loadingText] = React.useState("Generando factura...");
  const [loadingColor] = React.useState("#0763e7");

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <Paper
      elevation={4}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "40px",
        padding: "40px",
        width: "70vh",
        borderRadius: "12px",
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <BeatLoader
        css={override}
        size={15}
        color={loadingColor}
        loading={true}
      />
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px", color: "#333" }}
      >
        {loadingText}
      </Typography>
    </Paper>
  );
};

export default LoadingScreen;
