import React from "react";
import { Typography, Box, Paper, Grid } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const ResumenVenta = ({ orderMercadoLibre }) => {
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        color: "black",
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
        mt: 3,
      }}
    >
      <Typography
        fontFamily="Poppins"
        variant="h6"
        fontWeight={"bold"}
        mb={0}
        display="flex"
        alignItems="center"
        color="#0763e7"
      >
        <ShoppingCartIcon fontSize="large" sx={{ marginRight: "5px" }} />
        Resumen de la venta
      </Typography>
      <Typography
        fontFamily="Poppins"
        variant="subtitle1"
        ml={5}
        mb={2}
        color="textSecondary"
        fontSize={"17px"}
      >
        Pedido #{orderMercadoLibre[0]?.orderIdPackIdLabel}
      </Typography>

      {orderMercadoLibre && orderMercadoLibre.length > 0 ? (
        <Grid container spacing={2} mt={2}>
          {orderMercadoLibre.map((item) => (
            <Grid item key={item.itemId} xs={12}>
              <Box borderBottom="1px solid #ddd" pb={2}>
                <Typography fontFamily="Poppins" variant="body1" color="#333">
                  {item.itemId === "-1" ? (
                    <strong>{item.title}</strong>
                  ) : (
                    <span>
                      <strong>{item.itemId}</strong> - {item.title}
                    </span>
                  )}
                </Typography>

                <Typography
                  fontFamily="Poppins"
                  variant="body2"
                  color="textSecondary"
                  fontSize="15px"
                  mt={1}
                >
                  Cantidad: {formatNumber(item.quantity)} - Precio unitario: ${" "}
                  {formatNumber(item.unitPrice)}
                </Typography>
              </Box>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Typography
              fontFamily="Poppins"
              fontWeight="bold"
              variant="body1"
              mt={2}
              fontSize="20px"
              color="#333"
            >
              Total: $
              {formatNumber(
                orderMercadoLibre.reduce(
                  (total, item) => total + item.unitPrice * item.quantity,
                  0
                )
              )}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body1" mt={2} color="#555">
          No hay detalles de la venta disponibles.
        </Typography>
      )}
    </Paper>
  );
};

export default ResumenVenta;
