import React from "react";
import { Paper, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

const ErrorFeedback = ({ errorMessage }) => (
  <Paper
    elevation={4}
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "40px",
      padding: "40px",
      width: "70vh",
      borderRadius: "12px",
      textAlign: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }}
  >
    <ErrorOutline
      style={{ color: "#0763e7", fontSize: 36, marginTop: "20px" }}
    />
    <Typography
      variant="h5"
      style={{ marginTop: "5px", color: "#0763e7" }}
      gutterBottom
    >
      ¡Error al generar la factura!
    </Typography>
    <Typography
      variant="body1"
      style={{ marginBottom: "24px", color: "black" }}
    >
      {errorMessage ||
        "Ha ocurrido un error inesperado. Por favor, inténtelo de nuevo más tarde."}
    </Typography>
  </Paper>
);

export default ErrorFeedback;
