import React, { useState, useEffect } from "react";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Paper, Typography, Box } from "@mui/material";
import ResumenVenta from "../ResumenVenta/ResumenVenta";
import Footer from "../Footer/Footer";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { opcionesUsoCfdi } from "../utils/OpcionesUsoCfdi";
import { opcionesRegimenFiscal } from "../utils/OpcionesRegimenFiscal";
import FormularioCampos from "./FormularioCampos";
import SuccessFeedback from "../Feedback/SuccessFeedback";
import ErrorFeedback from "../Feedback/ErrorFeedback";
import "./Formulario.css";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

opcionesRegimenFiscal.sort((a, b) => a.label.localeCompare(b.label));
opcionesUsoCfdi.sort((a, b) => a.label.localeCompare(b.label));

const Formulario = ({ onCancel, orderMercadoLibre, orderToken }) => {
  const [rfcCliente, setRfcCliente] = useState("");
  const [rfcError, setRfcError] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [regimen, setRegimen] = useState("");
  const [usoCfdi, setUsoCfdi] = useState("");
  const [codPostal, setCodPostal] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessFeedback, setShowSuccessFeedback] = useState(false);
  const [showErrorFeedback, setShowErrorFeedback] = useState(false);
  const [datosComprobante, setDatosComprobante] = useState("");

  useEffect(() => {
    if (orderMercadoLibre && orderMercadoLibre.length > 0) {
      setCodPostal(orderMercadoLibre[0].billingZipCode);
    }
  }, [orderMercadoLibre]);

  const handleCancel = () => {
    onCancel();
  };

  const renderLoadingScreen = () => <LoadingScreen />;

  const renderFormOrFeedback = () => {
    if (showSuccessFeedback) {
      return (
        <SuccessFeedback
          datosComprobante={datosComprobante}
          orderMercadoLibre={orderMercadoLibre}
        />
      );
    } else if (showErrorFeedback) {
      return <ErrorFeedback />;
    } else {
      return renderForm();
    }
  };

  const renderForm = () => (
    <Paper
      elevation={4}
      style={{
        padding: "0",
        marginTop: "20px",
        maxWidth: "170vh",
        backgroundColor: "#f7f7f7fa",
      }}
    >
      <div
        style={{
          backgroundColor: "#0763e7",
          padding: "10px",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          style={{ color: "#fff", marginBottom: "0px" }}
        >
          Formulario de Facturación
        </Typography>
      </div>

      <div style={{ padding: "20px" }}>
        <form onSubmit={handleFormSubmit}>
          <FormularioCampos
            rfcCliente={rfcCliente}
            setRfcCliente={setRfcCliente}
            razonSocial={razonSocial}
            setRazonSocial={setRazonSocial}
            regimen={regimen}
            setRegimen={setRegimen}
            usoCfdi={usoCfdi}
            setUsoCfdi={setUsoCfdi}
            codPostal={codPostal}
            setCodPostal={setCodPostal}
            email={email}
            setEmail={setEmail}
            rfcError={rfcError}
          />

          <ResumenVenta orderMercadoLibre={orderMercadoLibre} />

          <Box mt={3} display="flex" justifyContent="center">
            <Button
              onClick={handleCancel}
              style={{ marginRight: "10px", fontSize: "16px" }}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#0763e7",
                color: "#fff",
                fontSize: "16px",
              }}
            >
              Generar Factura
            </Button>
          </Box>
        </form>
      </div>
    </Paper>
  );

  const validarRFC = (rfc) => {
    const patronRFC =
      /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/;

    if (!patronRFC.test(rfc)) {
      setRfcError(
        "El RFC ingresado no cumple con el formato válido. Por favor, verifique que tenga 12 o 13 caracteres y siga el formato correcto (ejemplo: TEST010203001)."
      );
      return false;
    } else {
      setRfcError("");
      return true;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validarRFC(rfcCliente)) {
      return;
    }

    setLoading(true);

    const formData = {
      orderToken,
      rfcCliente,
      razonSocial,
      regimen,
      usoCfdi,
      codPostal,
      email,
    };

    try {
      const response = await fetch(
        `${window._env_.REACT_APP_BFF_URL}/generateInvoice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        setShowErrorFeedback(true);
      } else {
        await verifyInvoice();
      }
    } catch (error) {
      setShowErrorFeedback(true);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  };

  const verifyInvoice = async () => {
    try {
      const response = await axios.get(
        `${window._env_.REACT_APP_BFF_URL}/verifyInvoice`,
        {
          params: {
            orderToken: orderToken,
          },
        }
      );

      const datosComprobante = response.data;
      setDatosComprobante(datosComprobante);

      if (
        datosComprobante.idCompVenta &&
        datosComprobante.xml &&
        datosComprobante.tokenComp
      ) {
        setShowSuccessFeedback(true);
      } else {
        setShowErrorFeedback(true);
      }
    } catch (error) {
      setShowErrorFeedback(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="formulario">
        {loading ? renderLoadingScreen() : renderFormOrFeedback()}

        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default Formulario;
