import { Typography } from "@mui/material";
import "../../css/App.css";
import DuxLogo from "../../assets/dux-logo.png";

function Home() {
  return (
    <div
      className="App"
      style={{ flexDirection: "column", textAlign: "center" }}
    >
      <img src={DuxLogo} className="duxLogo" alt="Dux Logo" />

      <Typography variant="h1" mt={5} fontFamily="Poppins" fontSize="25px">
        Ingrese un token del pedido para continuar. Consulte con
        el software de gestión.
      </Typography>
    </div>
  );
}

export default Home;
